import React, { Component } from 'react'

import { NavbarBrand } from 'reactstrap';

class TopNavBarLogo extends Component {

	render() {
		return (<NavbarBrand>Logo</NavbarBrand>);
	}

}

module.exports = TopNavBarLogo;