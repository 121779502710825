import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

import AppAuthRoutes from '../../../../../../../../../../resources/assets/react-app/routes/authRoutes';

import asyncComponent from 'Demora/AsyncComponent'

let Settings = asyncComponent(() => import(/* webpackChunkName: "prot-auth-settings" */ './Settings/Settings'));

const routes = AppAuthRoutes.concat([

	{
		path: '/home',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-auth-authHome" */ '../../../../../../../../../../resources/assets/react-app/components/AuthHome')),
	},
	{
		path: '/settings',
		component: Settings,
	},
	{
		path: '/settings/profile',
		component: Settings,
	},
	{
		path: '/settings/security',
		component: Settings,
	},
	{
		path: '/settings/changeEmail',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts/:team',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts/:team/:teamTab',
		component: Settings,
	},
	{
		path: '/getStarted/:page?',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-auth-getStarted" */ '../../../../../../../../../../resources/assets/react-app/components/GetStarted')),
	},
	{
		path: '/setupExistingUser',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-auth-SetupExistingUser" */ './SetupExistingUser/SetupExistingUser')),
	},
	{
		path: '/referrals',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-auth-referrals" */ './Referrals/Referrals')),
	},
	{
		path: '/admin',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-admin-admin" */ './Admin/admin')),
	},
	{
		path: '/admin/:service',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-admin-admin" */ './Admin/admin')),
	},
	{
		path: '/notifications',
		component: asyncComponent(() => import(/* webpackChunkName: "prot-auth-notifications" */ './Notifications/Notifications')),
	}

]);

class Routes extends React.Component {

	render() {

		let that = this;

		return routes.map((r, i) => {

			return (<Route key={i} exact={r.exact===undefined?true:r.exact} path={r.path} render={(props) => {

				if (!that.props.user.id) {

					let location = this.props.location;

					let redi = location.pathname;

					if (location.search) {

						let se = location.search;

						se = se.replace('noLogin=1', '');

						redi += se;

					}

					return <Redirect to={'/login?redi='+encodeURIComponent(redi)} />;

				}

				let V1 = r.component;

				return <V1 {...props} />

			}} />);

		});

	}

}

export default withRouter(ReduxConnect(Routes, {

	user: 'user',

}));
