var ss = function () {

	var classCallCheck = function (instance, Constructor) {
	  if (!(instance instanceof Constructor)) {
		throw new TypeError("Cannot call a class as a function");
	  }
	};

	var createClass = function () {
	  function defineProperties(target, props) {
		for (var i = 0; i < props.length; i++) {
		  var descriptor = props[i];
		  descriptor.enumerable = descriptor.enumerable || false;
		  descriptor.configurable = true;
		  if ("value" in descriptor) descriptor.writable = true;
		  Object.defineProperty(target, descriptor.key, descriptor);
		}
	  }

	  return function (Constructor, protoProps, staticProps) {
		if (protoProps) defineProperties(Constructor.prototype, protoProps);
		if (staticProps) defineProperties(Constructor, staticProps);
		return Constructor;
	  };
	}();

	var _extends = Object.assign || function (target) {
	  for (var i = 1; i < arguments.length; i++) {
		var source = arguments[i];

		for (var key in source) {
		  if (Object.prototype.hasOwnProperty.call(source, key)) {
			target[key] = source[key];
		  }
		}
	  }

	  return target;
	};

	var inherits = function (subClass, superClass) {
	  if (typeof superClass !== "function" && superClass !== null) {
		throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
	  }

	  subClass.prototype = Object.create(superClass && superClass.prototype, {
		constructor: {
		  value: subClass,
		  enumerable: false,
		  writable: true,
		  configurable: true
		}
	  });
	  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	};

	var possibleConstructorReturn = function (self, call) {
	  if (!self) {
		throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
	  }

	  return call && (typeof call === "object" || typeof call === "function") ? call : self;
	};

	var Connector = function () {
		function Connector(options) {
			classCallCheck(this, Connector);

			this._defaultOptions = {
				auth: {
					headers: {}
				},
				host: 'http://127.0.0.1:6001',//https://api.livepusher.com:6001',
				path: '/livePusher',
				key: null,
				namespace: 'App.Events',
				authChannel: function(socketId, channel) {
					alert('Need to do this');
				}
			};
			this.setOptions(options);
			this.connect();
		}

		createClass(Connector, [{
			key: 'setOptions',
			value: function setOptions(options) {
				this.options = _extends(this._defaultOptions, options);
				return options;
			}
		}]);
		return Connector;
	}();

	var Channel = function () {
		function Channel() {
			classCallCheck(this, Channel);
		}

		createClass(Channel, [{
			key: 'notification',
			value: function notification(callback) {
				return this.listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', callback);
			}
		}, {
			key: 'listenForWhisper',
			value: function listenForWhisper(event, callback) {
				return this.listen('.client-' + event, callback);
			}
		}]);
		return Channel;
	}();

	var EventFormatter = function () {
		function EventFormatter(namespace) {
			classCallCheck(this, EventFormatter);

			this.setNamespace(namespace);
		}

		createClass(EventFormatter, [{
			key: 'format',
			value: function format(event) {
				if (event.charAt(0) === '.' || event.charAt(0) === '\\') {
					return event.substr(1);
				} else if (this.namespace) {
					event = this.namespace + '.' + event;
				}
				return event.replace(/\./g, '\\');
			}
		}, {
			key: 'setNamespace',
			value: function setNamespace(value) {
				this.namespace = value;
			}
		}]);
		return EventFormatter;
	}();

	var SocketIoChannel = function (_Channel) {
		inherits(SocketIoChannel, _Channel);

		function SocketIoChannel(socket, name, options) {
			classCallCheck(this, SocketIoChannel);

			var _this = possibleConstructorReturn(this, (SocketIoChannel.__proto__ || Object.getPrototypeOf(SocketIoChannel)).call(this));

			_this.events = {};
			_this.name = name;
			_this.socket = socket;
			_this.options = options;
			_this.eventFormatter = new EventFormatter(_this.options.namespace);
			_this.subscribe();
			_this.configureReconnector();
			return _this;
		}

		createClass(SocketIoChannel, [{
			key: 'subscribe',
			value: function subscribe() {

				var _this = this;

				var t = this.t = (new Date).getTime();

				if (_this.socket.id) {

					this.emitSubscribe();

				}
				else
				{

					this.socket.on('connect', (socket) => {

						if (t == this.t) {

							this.emitSubscribe();

						}

					});

				}

			}
		},{
			key: 'emitSubscribe',
			value: function emitSubscribe() {
				var _this = this;

				let socketId = _this.socket.id;
				let channel = _this.name;

				_this.options.authPrivate(socketId, channel).then(auth => {
					if (auth && socketId == _this.socket.id) {
						_this.socket.emit('subscribe', {
							channel: channel,
							appId: _this.options.appId,
							authHash: auth,
							auth: _this.options.auth || {}
						});
					}
				});
			}
		}, {
			key: 'unsubscribe',
			value: function unsubscribe() {
				this.unbind();
				this.socket.emit('unsubscribe', {
					channel: this.name,
					auth: this.options.auth || {}
				});
			}
		}, {
			key: 'listen',
			value: function listen(event, callback) {
				this.on(this.eventFormatter.format(event), callback);
				return this;
			}
		}, {
			key: 'on',
			value: function on(event, callback) {
				var _this2 = this;

				var listener = function listener(channel, data) {
					if (_this2.name == channel) {
						callback(data);
					}
				};
				this.socket.on(event, listener);
				this.bind(event, listener);
			}
		}, {
			key: 'configureReconnector',
			value: function configureReconnector() {
				var _this3 = this;

				var listener = function listener() {
					_this3.subscribe();
				};
				this.socket.on('reconnect', listener);
				this.bind('reconnect', listener);
			}
		}, {
			key: 'bind',
			value: function bind(event, callback) {
				this.events[event] = this.events[event] || [];
				this.events[event].push(callback);
			}
		}, {
			key: 'unbind',
			value: function unbind() {
				var _this4 = this;

				Object.keys(this.events).forEach(function (event) {
					_this4.events[event].forEach(function (callback) {
						_this4.socket.removeListener(event, callback);
					});
					delete _this4.events[event];
				});
			}
		}]);
		return SocketIoChannel;
	}(Channel);

	var SocketIoPrivateChannel = function (_SocketIoChannel) {
		inherits(SocketIoPrivateChannel, _SocketIoChannel);

		function SocketIoPrivateChannel() {
			classCallCheck(this, SocketIoPrivateChannel);
			return possibleConstructorReturn(this, (SocketIoPrivateChannel.__proto__ || Object.getPrototypeOf(SocketIoPrivateChannel)).apply(this, arguments));
		}

		createClass(SocketIoPrivateChannel, [{
			key: 'whisper',
			value: function whisper(eventName, data) {
				this.socket.emit('client event', {
					channel: this.name,
					event: 'client-' + eventName,
					data: data
				});
				return this;
			}
		}]);
		return SocketIoPrivateChannel;
	}(SocketIoChannel);

	var SocketIoPresenceChannel = function (_SocketIoPrivateChann) {
		inherits(SocketIoPresenceChannel, _SocketIoPrivateChann);

		function SocketIoPresenceChannel() {
			classCallCheck(this, SocketIoPresenceChannel);
			return possibleConstructorReturn(this, (SocketIoPresenceChannel.__proto__ || Object.getPrototypeOf(SocketIoPresenceChannel)).apply(this, arguments));
		}

		createClass(SocketIoPresenceChannel, [{
			key: 'here',
			value: function here(callback) {
				this.on('presence:subscribed', function (members) {
					callback(members.map(function (m) {
						return m.user_info;
					}));
				});
				return this;
			}
		}, {
			key: 'joining',
			value: function joining(callback) {
				this.on('presence:joining', function (member) {
					return callback(member.user_info);
				});
				return this;
			}
		}, {
			key: 'leaving',
			value: function leaving(callback) {
				this.on('presence:leaving', function (member) {
					return callback(member.user_info);
				});
				return this;
			}
		}]);
		return SocketIoPresenceChannel;
	}(SocketIoPrivateChannel);

	var SocketIoConnector = function (_Connector) {
		inherits(SocketIoConnector, _Connector);

		function SocketIoConnector() {
			var _ref;

			classCallCheck(this, SocketIoConnector);

			for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
				args[_key] = arguments[_key];
			}

			var _this = possibleConstructorReturn(this, (_ref = SocketIoConnector.__proto__ || Object.getPrototypeOf(SocketIoConnector)).call.apply(_ref, [this].concat(args)));

			_this.channels = {};
			return _this;
		}

		createClass(SocketIoConnector, [{
			key: 'connect',
			value: function connect() {
				this.socket = io(this.options.host, this.options);
				return this.socket;
			}
		}, {
			key: 'listen',
			value: function listen(name, event, callback) {
				return this.channel(name).listen(event, callback);
			}
		}, {
			key: 'channel',
			value: function channel(name) {
				if (!this.channels[name]) {
					this.channels[name] = new SocketIoChannel(this.socket, name, this.options);
				}
				return this.channels[name];
			}
		}, {
			key: 'privateChannel',
			value: function privateChannel(name) {
				if (!this.channels['private-' + name]) {
					this.channels['private-' + name] = new SocketIoPrivateChannel(this.socket, 'private-' + name, this.options);
				}
				return this.channels['private-' + name];
			}
		}, {
			key: 'presenceChannel',
			value: function presenceChannel(name) {
				if (!this.channels['presence-' + name]) {
					this.channels['presence-' + name] = new SocketIoPresenceChannel(this.socket, 'presence-' + name, this.options);
				}
				return this.channels['presence-' + name];
			}
		}, {
			key: 'leave',
			value: function leave(name) {
				var _this2 = this;

				var channels = [name, 'private-' + name, 'presence-' + name];
				channels.forEach(function (name) {
					if (_this2.channels[name]) {
						_this2.channels[name].unsubscribe();
						delete _this2.channels[name];
					}
				});
			}
		}, {
			key: 'socketId',
			value: function socketId() {
				return this.socket.id;
			}
		}, {
			key: 'disconnect',
			value: function disconnect() {
				this.socket.disconnect();
			}
		}]);
		return SocketIoConnector;
	}(Connector);

	return function () {
		function Echo(options) {
			classCallCheck(this, Echo);

			this.options = options;
			this.connector = new SocketIoConnector(this.options);

		}

		createClass(Echo, [ {
			key: 'channel',
			value: function channel(_channel) {
				return this.connector.channel(_channel);
			}
		}, {
			key: 'private',
			value: function _private(channel) {
				channel = this.options.appId+'-'+channel;
				return this.connector.privateChannel(channel);
			}
		},{
			key: 'join',
			value: function join(channel) {
				channel = this.options.appId+'-'+channel;
				return this.connector.presenceChannel(channel);
			}
		},{
			key: 'listen',
			value: function listen(event, callback) {
				return this.connector.listen(event, callback);
			}
		},{
			key: 'leave',
			value: function leave(channel) {
				this.connector.leave(channel);
			}
		}, {
			key: 'socketId',
			value: function socketId() {
				return this.connector.socketId();
			}
		}, {
			key: 'disconnect',
			value: function disconnect() {
				this.connector.disconnect();
			}
		}]);
		return Echo;
	}();

}();

export default ss;

