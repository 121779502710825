import React, { Component } from 'react';

import DrawModal from './drawModal';

let m = function(modal) {

	let redux = window.reduxStore;

	redux.dispatch({
		type: 'addModal',
		modal: modal,
	})

}

m.close = function(i) {

	let redux = window.reduxStore;

	redux.dispatch({
		type: 'closeModal',
		modal: i,
	})

};

m.show = function() {

	let redux = window.reduxStore;

	let modals = redux.getState().modals;

	return (Object.keys(modals).map((key, k) => {

		let mod = modals[key];

		return (<DrawModal
			key = {k}
			header={mod.header}
			body={mod.body}
			footer={mod.footer}
			close={mod.close}
			onClose={mod.onClose}
			dontAllowClose={mod.dontAllowClose}
			id={key}
			noFooter={mod.noFooter}
			className={mod.className}
			m={m}
		/>);

	}));

}

export default m;
