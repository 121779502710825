import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

import TopNavBarLogo from '../../../../../../../../../resources/assets/react-app/components/TopNavBarLogo';

const styles = {

  link: {

    fontWeight: 200

  },

};

class TopNav extends Component {

  render() {

    const website = this.props.website;

    return (<div className="navbarTop topBarLoggedOut navbar navbar-dark fixed-top">
        <div style={{flexGrow:2}}>
          <TopNavBarLogo />
        </div>
        <ul className="nav">
        {!website.signups?'':(
          <li className="nav-item">
            <Link className="nav-link" style={styles.link} to="/signup">Signup</Link>
          </li>)}
          <li className="nav-item">
           <Link className="nav-link" style={styles.link} to="/login">Login</Link>
          </li>
        </ul>
      </div>);

  }
}

export default ReduxConnect(TopNav,{

  website: 'website',

});
