import React from 'react';

import { withRouter } from 'react-router-dom';

import AuthRoutes from './Auth/Routes';

import OpenRoutes from './NoAuth/Routes';

import ReduxConnect from 'Demora/redux-connect';

class Router extends React.Component {

	constructor() {

		super();

		this.state = {

			path: null,

		};

	}

	componentDidMount() {

		this.runCheck();

	}

	componentDidUpdate(prevProps) {

		let props = this.props;

		if (props.location !== prevProps.location || !this.state.path) {

			this.runCheck();

		}

	}

	runCheck() {

		let props = this.props;

		let user = window.reduxStore.getState().user;

		let url = props.location.pathname;

	    if (user.id && !user.finishedGetStarted && !url.match(/\/getStarted/)) {

			props.history.push('/getStarted');

			return;

	    }

	    this.setState({

			path: url,

	    })

		window.scrollTo(0, 0);

	}

	render() {

		if (!this.state.path) {

			return null;

		}

		let wh = this.props.heightOfInnerInnerWindow;

		return (<div style={{ height: wh?wh:'' }}>

				<AuthRoutes location={location} />
				<OpenRoutes location={location} />

			</div>);

	}

}

export default withRouter(ReduxConnect(Router, {

	heightOfInnerInnerWindow: 'heightOfInnerInnerWindow',

}));
