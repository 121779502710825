let config = {

	addons: [],

	dataDomain: (typeof(document) === 'undefined')?'':(document.location.port==8080?'http://livepusher.app:8000':''),

	push: {
		on: false,
		onLoggedOut: false,
	}

};

module.exports = config;
