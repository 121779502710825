import React, { Component } from 'react';

class Announcements extends Component {

	render() {

		return null;

	}

}

export default Announcements;
