import React, { Component } from 'react';

class PostFooter extends Component {

	render() {

		return null;

	}

}

export default PostFooter;
