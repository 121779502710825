export default function(img, size) {

	return {

		header: document.getElementsByClassName('navbarTop')[0].offsetHeight+14,
		footer: document.getElementsByClassName('navbarBottom')[0].offsetHeight+14,

	};

};
