import React, { Component } from 'react';

import TopNav from './TopNav';
import Footer from './Footer';
import PreHeader from 'resources/assets/react-app/components/PreHeader';
import PostFooter from 'resources/assets/react-app/components/PostFooter';
import HeaderAboveContent from 'resources/assets/react-app/components/HeaderAboveContent';

import modal from 'Demora/modals/modal';

import cookieconsent from 'cookieconsent';
import cookieconsentcss from 'cookieconsent/build/cookieconsent.min.css';

import ReduxConnect from 'Demora/redux-connect';

import Router from '../app/Router';

import { withRouter } from 'react-router-dom';

import loadinggif from '../../../../images/loading2.gif';

import config from '../../../../../../../../../resources/assets/react-app/config';

import Announcements from './Announcements';

class App extends Component {

	constructor(props, context) {

		super(props, context);

		this.state = {

			user: props.user.id,
			loaded: false,
			path: null,
			TopNavAuth: undefined,

		};

	}

	componentDidMount() {

		window.cookieconsent.initialise({
			"theme": "ruf",
			"position": "bottom-right"
		});

		if (this.props.user.id) {

			this.loadAuthFiles();

		}
		else
		{

			this.setState({
				loaded: true,
			});

		}

	}

	componentDidUpdate() {

		if (this.props.user.id && !this.state.user) {

			this.setState({

				user: this.props.user.id,

			});

			this.loadAuthFiles();

		}

	}

	static getDerivedStateFromProps(nextProps, prevState) {

		let ret = {};

		let location = nextProps.location;

		let fullPath = location.pathname+'?'+location.search;

		if (fullPath !== prevState.path) {

			ret.path = fullPath;

			let element = document.getElementsByTagName('body')[0];

			if (config.hideHeader && config.hideHeader(location)) {

				element.classList.add('hide-header');

			}
			else
			{

				element.classList.remove('hide-header');

			}

			if (config.hideFooter && config.hideFooter(location)) {

				element.classList.add('hide-footer');

			}
			else
			{

				element.classList.remove('hide-footer');

			}

		}

		if (nextProps.user.id && !prevState.user) {

			ret.loaded = false;

		}

		if (Object.keys(ret).length == 0) {

			return null;

		}

		return ret;

	}

	loadAuthFiles() {

		Promise.all([

			import(/* webpackChunkName: "auth_stuff" */ '@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss'),
			import(/* webpackChunkName: "auth_stuff" */ '@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss'),
			import(/* webpackChunkName: "auth_stuff" */ '@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss'),
			import(/* webpackChunkName: "auth_stuff" */'./TopNavAuth'),

			new Promise(function(resolve, reject) {

				import(/* webpackChunkName: "auth_stuff" */ 'js-cookie').then((Cookies) => {

					let cookie = Cookies.getJSON('currentTeam');

					if (cookie) {

						let u = window.reduxStore.getState().user;

						u.currentTeam = cookie;

						window.reduxStore.dispatch({

							type: 'STORE_USER',

							user: u,

						});

					}

					resolve();

				});

			}),

		]).then(a => {

			this.setState({

				TopNavAuth: a[3].default,
				loaded: true,

			});

		});

	}

	render() {

		let state = this.state;

		let re = /[^a-z0-9_]/gi;

		let urlClassname = location.pathname.replace(re, '_');

		if (!state.loaded) {

			return (<div className="text-center"><img src={loadinggif} style={{maxWidth:'250px'}} /><br /><h3>Loading</h3></div>);

		}

		if (this.props.user.id) {

			urlClassname += ' authorisedUser';

		}

		let V = state.TopNavAuth;

		return (<div>

			<PreHeader />

			<div className={urlClassname}>

				{V?<V />:<TopNav />}
				<div id="pageContent">
					<HeaderAboveContent />
					<Announcements />
					<Router />
				</div>
				<Footer />

				{modal.show()}

			</div>

			<PostFooter />

		</div>);

	}

}

export default withRouter(ReduxConnect(App, {
	modals: 'modals', // required to trigger re-render for modals
	user: 'user',
}));
