import React, { Component } from 'react';

class PreHeader extends Component {

	render() {

		return null;

	}

}

export default PreHeader;
