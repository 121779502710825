import React, { Component } from 'react';

import ReduxConnect from 'Demora/redux-connect';

class Announcements extends Component {

	renderAnnouncement(a, i) {

		return (<div className={'alert alert-'+a.classContext} key={i}>

			{!!a.heading && <h4 className='alert-heading'>{a.heading}</h4>}
			{!!a.content && <div>{a.content}</div>}
			{!!a.footer && !!a.content && <hr className='mt-2 mb-2' />}
			{!!a.footer && <div>{a.footer}</div>}

		</div>);

	}

	authShouldntDisplay(can) {

		return can !== !!this.props.user.id;

	}

	render() {

		let props = this.props;

		let user = props.user;

		let announcements = user.announcements;

		let out = [];

		announcements && announcements.map((a, i) => {

			let p = window.location.pathname;

			if (a.auth !== undefined) {

				if (this.authShouldntDisplay(a.auth)) {

					return;

				}

			}

			if (a.except && a.except.includes(p)) {

				return;

			}

			if (a.only && !a.only.includes(p)) {

				return;

			}

			out.push(this.renderAnnouncement(a, i));

		});

		return out;

	}

}

export default ReduxConnect(Announcements, {

	user: 'user',

});
