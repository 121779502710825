import {connect} from 'react-redux';

import PropTypes from 'prop-types';

let redux_connect = function(Component, stores, opts) {

	if (Component.contextTypes) {

		Component.contextTypes.store = PropTypes.object;

	}
	else
	{

		Component.contextTypes = {

			store: PropTypes.object,

		};

	}

	function mapStateToProps(state) {

		let st = {};

		for (let i in stores) {

			st[i] = state[stores[i]];

		}

	  	return st;

	}

	function mapDispatchToProps(dispatch) {

	  return {};

	}

	function mergeProps(stateProps, dispatchProps, ownProps) {

		return Object.assign({}, stateProps, ownProps);

	}

	return connect(mapStateToProps, mapDispatchToProps, mergeProps, opts)(Component);

}

export default redux_connect;
