import React, { Component } from "react";

export default function asyncComponent(getComponent) {

    class AsyncComponent extends Component {

        static Component = null;

        state = {

            Component: AsyncComponent.Component,
            attempts: 0,

        };

        componentDidMount() {

            this.getComponent();

        }

        getComponent = () => {

            if (!this.state.Component) {

                getComponent().then(module => module.default).then(Component => {

                    AsyncComponent.Component = Component;

                    this.setState({ Component });

                }).catch(error => {

                    let attempts = this.state.attempts;

                    if (attempts >= 6) {

                        throw error;

                        return;

                    }

                    this.setState({

                        attempts: attempts + 1,

                    });

                    setTimeout(this.getComponent, this.state.attempts * 1000);

                });

            }

        }

        render() {

            const { Component } = this.state;

            if (Component) {

                return <Component {...this.props} />

            }

            return null;

        }

    }

    return AsyncComponent;

}
